/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { DemandAnalysis, DemandAnalysisQuestion, DemandAnalysisQuestionStatus } from '../../../api/schemas/schema'
import { Dialog, DialogTitle, DialogContent, Divider, RadioGroup, Radio, Button, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { Text } from '../../common/text/Text'
import { ControlledTextInput } from '../../common/inputs/TextInput'
import { useForm } from 'react-hook-form'
import { ModalActions, DialogSurface, DialogBody } from '../../common/modal/Modal'
import { api } from '../../../helpers/apiHelper'
import { DataContext } from '../../../context/DataContext'
import { handleMessage } from '../../../helpers/stateHelper'
import { ConnectedDemandPopover } from '../ConnectedDemandPopover'
import { Field } from '../../common/inputs/Field'

type ModalAnalysisQuestionProps = {
   item: DemandAnalysisQuestion
   close: () => void
   setItems: React.Dispatch<React.SetStateAction<DemandAnalysisQuestion[]>>
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >
}
const useStyles = makeStyles({
   formWrapper: {
      marginTop: tokens.spacingVerticalM,
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingVerticalL),
   },
   textWrapper: {
      '> p:last-child': {
         marginTop: tokens.spacingVerticalXS,
      },
      '> p:first-child': {
         marginBottom: 0,
      },
   },
})
export const ModalAnalysisQuestion = ({ item, close, setItems, setAnalysisState }: ModalAnalysisQuestionProps) => {
   const dataContext = useContext(DataContext)
   const { accessToken, userAccount } = dataContext.state
   const classes = useStyles()
   const { control, setValue, watch, reset, handleSubmit } = useForm<DemandAnalysisQuestion>()
   const { t } = useTranslation()
   useEffect(() => {
      reset(item)
   }, [item])
   const isClosed = item && item.status === DemandAnalysisQuestionStatus.Established
   const onSubmit = async (data: DemandAnalysisQuestion) => {
      try {
         if (!data.answer.actionRequired) setValue('proposedAction', null)
         if (!data.answeredById) {
            data.answeredById = userAccount.id
            data.answeredBy = userAccount
         }
         data.modifiedBy = userAccount
         data.modifiedById = userAccount.id
         const updatedQuestion: any = (await api(accessToken).updateDemandAnalysisQuestion(data)).data

         setItems((prev) => {
            const updatedItems = [...prev]
            updatedItems[updatedItems.findIndex((x) => x.id === updatedQuestion.value.id)] = updatedQuestion.value
            return updatedItems
         })
         setAnalysisState((prev) => {
            const updatedItems = [...prev.item.questions]
            updatedItems[updatedItems.findIndex((x) => x.id === updatedQuestion.value.id)] = updatedQuestion.value
            return { ...prev, item: { ...prev.item, questions: updatedItems } }
         })
         handleMessage(dataContext.setRootState, 'success', t('ItemSuccessfullyUpdated', { Item: t('Question') }))
         reset()
         close()
      } catch (error: any) {
         handleMessage(dataContext.setRootState, 'error', t('CouldNotCreateItem', { Item: t('Question') }), error.error.message)
      }
   }
   return (
      <Dialog open={item ? true : false} modalType={'modal'}>
         {item ? (
            <DialogSurface>
               <DialogBody>
                  <DialogTitle action={<ConnectedDemandPopover item={item} />} as="h3">
                     {t('ComplianceOfDemands')}
                  </DialogTitle>

                  <DialogContent>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Divider appearance="brand" />
                        <div className={classes.textWrapper}>
                           <Text weight="bold">{t('Question')}</Text>
                           <Text>{item.text}</Text>
                        </div>
                        <div className={classes.textWrapper}>
                           <Text weight="bold">{t('BackgroundInformation')}</Text>
                           <Text>{item.backgroundInformation}</Text>
                        </div>
                        {item.proposedActionTip && item.proposedActionTip.length > 0 ? (
                           <div className={classes.textWrapper}>
                              <Text weight="bold">{t('TipFrom365Robust')}</Text>
                              <Text>{item.proposedActionTip}</Text>
                           </div>
                        ) : null}
                        <Divider appearance="brand" />
                        <section className={classes.formWrapper}>
                           <Field label={t('SpecifyCompliance')} required>
                              <RadioGroup
                                 disabled={isClosed}
                                 value={watch('answerId') ?? ''}
                                 onChange={(e, data) => {
                                    setValue('answerId', data.value)
                                    setValue(
                                       'answer',
                                       item.answerOptions.find((x) => x.id === data.value)
                                    )
                                 }}
                              >
                                 {item.answerOptions.map((o) => (
                                    <Radio value={o.id} label={o.text} />
                                 ))}
                              </RadioGroup>
                           </Field>
                           {watch('answer') && watch('answer').actionRequired ? (
                              <ControlledTextInput
                                 disabled={isClosed}
                                 label={t('ProposedAction')}
                                 control={control}
                                 name={'proposedAction'}
                                 multiline
                                 rows={2}
                                 required
                              />
                           ) : null}
                           <ControlledTextInput disabled={isClosed} label={t('Comment')} control={control} name={'comment'} multiline rows={2} />
                           <ModalActions>
                              <Button
                                 onClick={() => {
                                    reset()
                                    close()
                                 }}
                              >
                                 {!isClosed ? t('Cancel') : t('Close')}
                              </Button>
                              {!isClosed && (
                                 <Button type="submit" appearance="primary">
                                    {t('Save')}
                                 </Button>
                              )}
                           </ModalActions>
                        </section>
                     </form>
                  </DialogContent>
               </DialogBody>
            </DialogSurface>
         ) : null}
      </Dialog>
   )
}
