import { Persona, TableColumnDefinition } from '@fluentui/react-components'
import { TFunction } from 'i18next'
import { DemandAnalysis, DemandAnalysisQuestion } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import dayjs from 'dayjs'
import { IAnalysisStatusData } from '../interfaces/IAnalysisStatusData'
import { BadgeAnalysisQuestion } from '../components/demandAnalysis/BadgeAnalysisQuestion'
import { api } from './apiHelper'
import { handleMessage } from './stateHelper'
import React from 'react'
import { findQuestionParentToActionPlan } from './actionPlanHelper'
import { IComplianceState } from '../interfaces/IComplianceState'
import { Avatar } from '../components/common/avatar/Avatar'

export enum ComplianceTabs {
   Ongoing = 'ongoing',
   Concluded = 'concluded',
}

export enum DemandAnalysisTabs {
   Info = 'info',
   Status = 'status',
   CurrentAnalysis = 'current-analysis',
   ActionPlan = 'actionplan',
}

export const getDemandAnalysesColumns = (t: TFunction<'translation', undefined>): TableColumnDefinition<DemandAnalysis>[] => [
   {
      columnId: 'name',
      renderHeaderCell: () => {
         return t('AnalysisName')
      },
      renderCell: (item: DemandAnalysis) => {
         return item.name
      },
      compare: (a, b) => {
         return a.name.localeCompare(b.name)
      },
   },
   {
      columnId: 'modifiedBy',
      renderHeaderCell: () => {
         return t('ModifiedBy')
      },
      renderCell: (item: DemandAnalysis) => {
         return <Avatar name={item.modifiedBy?.title} />
      },
      compare: (a, b) => {
         return a.modifiedBy?.title.localeCompare(b.modifiedBy?.title ?? '')
      },
   },
   {
      columnId: 'modified',
      renderHeaderCell: () => {
         return t('Modified')
      },
      renderCell: (item: DemandAnalysis) => {
         return dayjs(item.modified).format('YYYY-MM-DD HH:mm')
      },
      compare: (a, b) => {
         return new Date(a.modified).getHours() - new Date(b.modified).getHours()
      },
   },
]

export const getDefaultValues = (dataContext: IDataContext, complianceState: IComplianceState): DemandAnalysis => ({
   organisationId: dataContext.state.organisation.id,
   leadershipPerspectives: [],
   branchId: dataContext.state.branch.id,
   processes: [],
   subjects: [],
   analysisOwnerId: dataContext.state.userAccount.id,
   analysisOwner: dataContext.state.userAccount,
   dataClassification: ' ',
   order: complianceState.allDemandAnalyses.length + 1,
})

export const getDemandAnalysisStatusColumns = (t: TFunction<'translation', undefined>): TableColumnDefinition<IAnalysisStatusData>[] => [
   {
      columnId: 'process',
      renderHeaderCell: () => {
         return t('Process')
      },
      renderCell: (item) => {
         return item.process
      },
      compare: (a, b) => {
         return a.process.localeCompare(b.process)
      },
   },
   {
      columnId: 'processOwner',
      renderHeaderCell: () => {
         return t('ProcessOwner')
      },
      renderCell: (item) => {
         return <Persona textAlignment="center" name={item.processOwner.title} />
      },
      compare: (a, b) => {
         return a.processOwner.title.localeCompare(b.processOwner.title)
      },
   },
   {
      columnId: 'answered',
      renderHeaderCell: () => {
         return t('AllAnswered')
      },
      renderCell: (item) => {
         return `${item.numberOfAnsweredQuestions} (${item.numberOfTotalQuestions})`
      },
      compare: (a, b) => {
         return 1
      },
   },
   {
      columnId: 'modified',
      renderHeaderCell: () => {
         return t('Modified')
      },
      renderCell: (item) => {
         return item.modified ? dayjs(item.modified).format('YYYY-MM-DD') : ''
      },
      compare: (a, b) => {
         return new Date(a.modified).getHours() - new Date(b.modified).getHours()
      },
   },
   {
      columnId: 'status',
      renderHeaderCell: () => {
         return t('Status')
      },
      renderCell: (item) => {
         return item.status
      },
      compare: (a, b) => {
         return a.status.localeCompare(b.status)
      },
   },
]

export const getCurrentAnalysisColumns = (t: TFunction<'translation', undefined>): TableColumnDefinition<DemandAnalysisQuestion>[] => [
   {
      columnId: 'number',
      renderHeaderCell: () => t('No.'),
      renderCell: (item) => item.number,
      compare: (a, b) => a.number - b.number,
   },
   {
      columnId: 'question',
      renderHeaderCell: () => t('Question'),
      renderCell: (item) => item.text,
      compare: (a, b) => a.text.localeCompare(b.text),
   },
   {
      columnId: 'process',
      renderHeaderCell: () => t('Process'),
      renderCell: (item) => item.processes,
      compare: (a, b) => -1,
   },
   {
      columnId: 'processOwner',
      renderHeaderCell: () => t('ProcessOwner'),
      renderCell: (item) => 'Karl Karlsson',
      compare: (a, b) => -1,
   },
   {
      columnId: 'status',
      renderHeaderCell: () => t('Status'),
      renderCell: (item) => <BadgeAnalysisQuestion status={item.status} />,
      compare: (a, b) => -1,
   },
]

export const completeCurrentAnalysis = async (
   dataContext: IDataContext,
   items: DemandAnalysisQuestion[],
   t: TFunction<'translation', undefined>,
   setItems: React.Dispatch<React.SetStateAction<DemandAnalysisQuestion[]>>,
   setAnalysisState: React.Dispatch<
      React.SetStateAction<{
         loading: boolean
         error: Error
         item: DemandAnalysis
      }>
   >,
   closeModal: () => void
) => {
   try {
      setAnalysisState((prev) => ({ ...prev, loading: true }))
      const { accessToken, organisation, branch, userAccount } = dataContext.state
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const itemsToConclude = items.map((x) => ({ ...x, modifiedById: userAccount.id, modified: userAccount }))
      const data = (await api(accessToken).concludeCurrentAnalysis(organisation.id, { branchId: branch.id }, items)).data
      const { updatedQuestions, actionPlans } = data
      setItems((prev) => {
         const items = [...prev]
         updatedQuestions.forEach((question) => (items[items.findIndex((x) => x.id === question.id)] = question))
         return items
      })
      setAnalysisState((prev) => {
         const questions = [...prev.item.questions]
         updatedQuestions.forEach((question) => (questions[questions.findIndex((x) => x.id === question.id)] = question))
         return {
            ...prev,
            item: {
               ...prev.item,
               questions: questions,
               actionPlans: [...prev.item.actionPlans, ...actionPlans].sort(
                  (a, b) => findQuestionParentToActionPlan(questions, a).number - findQuestionParentToActionPlan(questions, b).number
               ),
            },
         }
      })
      handleMessage(dataContext.setRootState, 'success', t('CurrentAnalysisCompleted'))
      setAnalysisState((prev) => ({ ...prev, loading: false }))
      closeModal()
   } catch (error: any) {
      handleMessage(dataContext.setRootState, 'success', t('CouldNotCompleteCurrentAnalysis'), error.error)
      setAnalysisState((prev) => ({ ...prev, loading: false }))
   }
}
export const filterQuestionsByProcess = (
   selectedProcess: string,
   questions: DemandAnalysisQuestion[],
   t: TFunction<'translation', undefined>
): DemandAnalysisQuestion[] => {
   let filteredItems = []
   if (selectedProcess === t('AllProcesses')) filteredItems = questions
   else filteredItems = questions.filter((x) => x.processes.some((p) => p === selectedProcess))
   return filteredItems
}

export const getDemandAnalysisTitle = (selectedTab: string, t: TFunction<'translation', undefined>): string => {
   switch (selectedTab) {
      case DemandAnalysisTabs.Info:
         return t('AnalysisInformation')
      case DemandAnalysisTabs.ActionPlan:
         return t('ActionPlan')
      case DemandAnalysisTabs.Status:
         return t('AnalysisStatus')
      case DemandAnalysisTabs.CurrentAnalysis:
         return null
      // return t('CurrentSituationAnalysis')
   }
}
